/* eslint-disable no-console */

import * as Sentry from '@sentry/browser'

const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV
const isDevelopment = REACT_APP_NODE_ENV === 'development'
const isProduction = REACT_APP_NODE_ENV === 'production'

export const logger = {
  error: (errorMsg, extra) => {
    if (isDevelopment || !REACT_APP_NODE_ENV) {
      if (extra !== undefined) {
        console.error(errorMsg, extra)
      } else {
        console.error(errorMsg)
      }
    } else {
      Sentry.captureException(errorMsg, {
        extra: typeof extra === 'object' ? extra : undefined
      })
    }
  },

  warn: (warning) => {
    if (!isProduction) {
      console.warn(warning)
    }
  },

  info: (info) => {
    if (isDevelopment) {
      console.log(info)
    }
  },

  dir: (directory) => {
    if (isDevelopment) {
      console.dir(directory)
    }
  }
}
