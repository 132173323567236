
import { createContext, useContext, useState, useEffect } from 'react'
import queryString from 'query-string'
import { logger } from '@utils'
import { ITaskState, initialTaskState, initialEmailState, IQueryState } from '@context/taskContext'

const TaskContext = createContext<ITaskState>({ query: initialTaskState, ...initialEmailState })
interface IProps {
  children: React.ReactNode
}
export const useTask = () => {
  const context = useContext(TaskContext)
  if (!context) {
    logger.error(' Please call within a AppThemeProvider')
    throw new Error('useApptheme must be used within a AppThemeProvider')
  }

  return context
}

export const TaskProvider = (props: IProps) => {
  const [query, setQuery] = useState<IQueryState>({ ...initialTaskState })
  const [emailAddress, setEmailAddress] = useState<string>('')

  useEffect(() => {
    const query = queryString.parse(decodeURIComponent(location.search))
    setQuery({ token: query.token, action: query.action, description: query.description, slid: query.slid })
  }, [location])

  return <TaskContext.Provider value={{ query, emailAddress, setEmailAddress }}>{props.children}</TaskContext.Provider>
}
