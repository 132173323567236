import styled from 'styled-components'
import { Card, CardContent, Typography, Button } from '@mui/material'
import { THEME_COLORS } from '@theme'

export const ConnectionText = styled(Typography)`
  margin-right: 10px;
`

export const BoldText = styled(Typography)`
  font-weight: 700;
`

export const TaskTitleText = styled(Typography)`
font-weight: 700;
font-size: 20px;
`

export const ConnectionHeaderCardContent = styled(CardContent)`
  display: flex;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`

export const TaskCard = styled(Card)`
  width: 700px;
  margin: auto;
  margin-bottom: 30px;
  @media only screen and (max-width: 900px) {
    width: 100%;
    box-shadow: 0px 1px 0px 0 #0003;
    margin-bottom: 30px;
  }
`

export const EmailLink = styled(Button)`
  color: ${THEME_COLORS.blue};
  margin-top: -5px;
  text-decoration: underline;
  @media only screen and (max-width: 600px) {
    display: contents;
  }
`

export const CalendarIcon = styled.i`
color: ${THEME_COLORS.red};
--fa-secondary-color: ${THEME_COLORS.lightGreyBlue};
font-size: 48px;
`

export const ExpirationText = styled.div`
  margin-left: 5px;
  margin-bottom: 5px;
`

export const InstructionText = styled.div`
  margin-top: 10px;
`

export const CalendarContainer = styled.div`
  display: flex;
  margin: 20px 0px 20px 0px;
`

export const WidgetCardContent = styled(CardContent)`
  padding: 0px!important;
`
