import styled from 'styled-components'
import { Button } from '@mui/material'
import { THEME_COLORS } from '@theme'

export const BrandingTitle = styled.div`
height: 40%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: start;

& img {
  height: 48px;
  padding: 4px 0;

  @media only screen and (max-width: 600px) {
    height: 24px;
  }
}
`

export const BrandingPlaceholder = styled.div`
  padding: 7px 0 7px 32px;
  background: ${THEME_COLORS.backgroundGrey};
  min-height: 56px;
`

export const BrandingContainer = styled.div`
`

export const PrivacyLink = styled(Button)`
  color: ${THEME_COLORS.blue};
  fontWeight: '700';
`

export const PrivacyContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
