import { $TSFixMe } from '@src/types'
import { Dispatch } from 'react'
import type {
  SET_PARTNER_ID as SET_PARTNER_ID_TYPE,
  FETCHING_CURRENT_BRAND as FETCHING_CURRENT_BRAND_TYPE,
  FETCHED_CURRENT_BRAND as FETCHED_CURRENT_BRAND_TYPE,
  ERROR_FETCHING_CURENT_BRAND as ERROR_FETCHING_CURENT_BRAND_TYPE,
  ERROR_FETCHING_PARTNER_ID as ERROR_FETCHING_PARTNER_ID_TYPE,
  FETCHING_PARTNER_ID as FETCHING_PARTNER_ID_TYPE
} from '@context/themeContext'

export enum THEME_KEYS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export interface IThemeColor {
  main: string
}

export interface IAppTheme {
  pallette: {
    [THEME_KEYS.PRIMARY]: IThemeColor
    [THEME_KEYS.SECONDARY]: IThemeColor
    [THEME_KEYS.TERTIARY]: IThemeColor
  }
  typography: {
    fontFamily: string[]
  }
  overrides: $TSFixMe
  props: $TSFixMe
  components: $TSFixMe
}

export interface IStyledTheme {
  brandName: string
  logo: string
  favicon: string
  brand: string
  main: string
  partnerId: string
}

export interface IActions {
  type:
    | SET_PARTNER_ID_TYPE
    | FETCHING_CURRENT_BRAND_TYPE
    | FETCHED_CURRENT_BRAND_TYPE
    | ERROR_FETCHING_CURENT_BRAND_TYPE
    | ERROR_FETCHING_PARTNER_ID_TYPE
    | FETCHING_PARTNER_ID_TYPE
  payload?: unknown
}

export interface IThemeState {
  fetchingPatnerId: boolean
  errorFetchingPartnerId: string
  partnerId: string | null
  branding: {
    logoUrl: string
    displayName: string
    favIconUrl: string
    palette: {
      primaryColor: string
      secondaryColor: string
      tertiaryColor: string
    }
  }
  fetchingBranding: boolean
  errorFetchingBranding: string
  dispatch?: Dispatch<IActions>
}
