import { useEffect } from 'react'
import { Header, AppRoute } from '@components'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { TaskProvider } from '@context/taskContext'

export const Layout = () => {
  const ldclient = useLDClient()

  useEffect(() => {
    const initializeLDClient = async (): Promise<void> => {
      if (ldclient?.identify) {
        try {
          await ldclient.identify({
            anonymous: true,
            custom: {
              domain: window.location.hostname
            }
          })
        } catch (_error) {}
      }
    }
    void initializeLDClient()
  }, [])

  return (
    <div>
      <Header />
      <TaskProvider>
        <AppRoute />
      </TaskProvider>
    </div>
  )
}

export default Layout
