/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo, useEffect, useReducer, createContext, useContext } from 'react'
import { ThemeProvider } from 'styled-components'
import { SnackbarProvider } from 'notistack'
import { createTheme, ThemeProvider as MuiThemeProvider, responsiveFontSizes, StyledEngineProvider } from '@mui/material/styles'
import StylesProvider from '@mui/styles/StylesProvider'
import { Helmet } from 'react-helmet'
import { logger } from '@utils'
import { AppLoader } from '@common'
import { THEME_COLORS, defaultComponent, defaultProps } from '@theme'
import { getBrandingDetails, reducer, initialThemState, IThemeState } from '@context/themeContext'

interface IProps {
  children: React.ReactNode
}

const AppThemecontext = createContext<IThemeState>({ ...initialThemState })

export const useAppTheme = () => {
  const context = useContext(AppThemecontext)
  if (!context) {
    logger.error(' Please call within a AppThemeProvider')
    throw new Error('useApptheme must be used within a AppThemeProvider')
  }

  return context
}

export const AppThemeProvider = (props: IProps) => {
  const [state, dispatch] = useReducer(reducer, { ...initialThemState })

  useEffect(() => {
    void (async () => getBrandingDetails({ dispatch }))()
  }, [])

  const appTheme = useMemo(() => {
    if (state.branding) {
      const theme = state.branding
      const convertedMUITheme = {
        ...theme,
        palette: {
          primary: { main: theme.palette.primaryColor },
          secondary: { main: theme.palette.secondaryColor },
          tertiary: { main: theme.palette.tertiaryColor }
        },
        typography: {
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
        },
        props: defaultProps,
        components: defaultComponent
      }
      const customTheme = createTheme(convertedMUITheme)
      const MUITheme = responsiveFontSizes(customTheme)
      // theme for styled component theme system
      const styledTheme = {
        logo: theme.logoUrl,
        favicon: theme.favIconUrl,
        main: theme.palette.primaryColor,
        ...customTheme
      }
      return { MUITheme, styledTheme }
    }
    const customTheme = createTheme({
      palette: {
        primary: { main: THEME_COLORS.blue },
        secondary: { main: THEME_COLORS.greyBlue }
      },
      typography: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
      }
    })
    const MUITheme = responsiveFontSizes(customTheme)
    return { MUITheme, styledTheme: initialThemState }
  }, [state])

  const value = useMemo(
    () => ({
      ...state,
      dispatch
    }),
    [state]
  )

  if (state.fetchingBranding) return <AppLoader />

  return (
    <AppThemecontext.Provider value={value}>
      <StylesProvider injectFirst>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{state.branding.displayName || 'Welcome to the Portal'}</title>
          <link rel='icon' href={state.branding.favIconUrl} type='image/png' />
        </Helmet>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={appTheme.MUITheme}>
            <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} hideIconVariant={true}>
              <ThemeProvider theme={appTheme.styledTheme}>{props.children}</ThemeProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </AppThemecontext.Provider>
  )
}
