export const THEME_COLORS = {
  drawerBackgroundGrey: '#F2F2F2',
  lightGrey: '#DCDFEA',
  grey: '#CED4D9',
  darkishGrey: '#999999',
  darkGrey: '#565A5C',
  darkGrey2: '#5B5C5F',
  midGrey: '#979797',
  midGrey2: '#b3b4b7',
  greyBlue: '#8b99a6',
  lightBlue: '#E7F3F7',
  blue: '#1182AC',
  boldBlue: '#3E88FF',
  lightGreen: '#e8faf4',
  green: '#04AA6D',
  boldGreen: '#2bb513',
  primaryGreen: '#228477',
  persianGreen: '#00A699',
  red: '#EC5353',
  lightRed: '#FDF3F1',
  lightOrangeWithOpacity: '#ffb4361a',
  orange: '#fcb437',
  black: '#111111',
  darkBlue: '#01435D',
  white: '#FFFFFF',
  aquamarine: '#6FEFC1',
  purple: '#D7B8FF',
  darkPurple: '#9747FF',
  blueCerulean: '#0093ED',
  lightViolet: '#6772E5',
  yellowOrange: '#FFB436',
  backgroundGrey: '#F4F5F8',
  lightGreyBlue: '#859BEC'
}
