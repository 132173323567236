import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom'
import { VerificationFlow, TaskFlow } from '@components'

export const AppRoute = () => {
  const [searchParams] = useSearchParams()
  return (
    <Routes>
      <Route path='/app/verification' element={<VerificationFlow />} />
      <Route path='/app/task' element={<TaskFlow />} />
      <Route path='*' element={<Navigate to={`/app/verification?${searchParams.toString()}`}/>} />
    </Routes>
  )
}
