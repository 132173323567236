import { IThemeState } from '@context/themeContext'

export const initialThemState: IThemeState = {
  fetchingPatnerId: false,
  errorFetchingPartnerId: '',
  partnerId: null,
  branding: {
    logoUrl: '',
    displayName: '',
    favIconUrl: '',
    palette: {
      primaryColor: '#nnnnnn',
      secondaryColor: '#nnnnnn',
      tertiaryColor: '#nnnnnn'
    }
  },
  fetchingBranding: false,
  errorFetchingBranding: ''
}

export const snackbarSetting = {
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  hideIconVariant: true
}
