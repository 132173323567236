/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { FormControlLabel, Checkbox, InputLabel, OutlinedInput, Button, CardContent, Grid, FormHelperText, Link } from '@mui/material'
import {
  MarginedText,
  FormActions,
  Container,
  MarginedTextHeader,
  BackButton,
  ResendLink,
  ResendContainer,
  VerifyButton,
  InputGrid,
  StyleEmailLabel,
  TermsContainer,
  TermsLink
} from '@components/verification/styles'
import { useTask } from '@context/taskContext'
import { useSnackbar } from 'notistack'
import Cookies from 'js-cookie'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { axiosInstance } from '@utils'
import { CookieKeys } from '@constants'
import { useAppTheme } from '@context/themeContext'
import { AxiosError } from 'axios'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const VerificationPage = () => {
  const navigate = useNavigate()
  const themeContext = useAppTheme()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [codeSent, setCodeSent] = useState<boolean>(false)
  const [rememberDevice, setRememberDevice] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const {
    query: { token, description, action },
    setEmailAddress
  } = useTask()
  const [searchParams] = useSearchParams()
  const toggleRememberDevice = () => {
    setRememberDevice((value) => !value)
  }
  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (emailError) setEmailError('')
    setEmail(e.target.value)
  }
  const handleCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value)
  }

  const hanldeSendCode = async () => {
    const UMS_URL = process.env.REACT_APP_UMS_SERVICE
    if (!email.match(emailRegex)) {
      setEmailError('Enter a valid email address.')
      return
    }
    if (UMS_URL !== undefined && token) {
      const url = `${UMS_URL}/v1/smart-link/verification-code/generate`
      const body = JSON.stringify({
        email
      })
      try {
        const response = await axiosInstance.post(url, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.toString()}`
          }
        })
        if (response?.status === 200) {
          enqueueSnackbar('Verification code has been sent to your email address.', { variant: 'success' })
          setCodeSent(true)
        } else {
          enqueueSnackbar('There is an issue with this link. Please request a new link from your accountant.', { variant: 'error' })
          setCodeSent(false)
        }
      } catch (error) {
        let errorMessage = 'There is an issue with this link. Please request a new link from your accountant.'
        if (error instanceof AxiosError && error?.response?.status === 401) errorMessage = 'This link has expired. Please request a new link from your accountant.'
        enqueueSnackbar(errorMessage, { variant: 'error' })
        setCodeSent(false)
      }
    }
  }

  const hanldeVeify = async () => {
    const UMS_URL = process.env.REACT_APP_UMS_SERVICE
    if (UMS_URL !== undefined && token) {
      const URL = `${UMS_URL}/v1/smart-link/verification-code/validate`
      const body = JSON.stringify({
        emailAddress: email,
        remember90Days: rememberDevice,
        code
      })
      try {
        const response = await axiosInstance.post(URL, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.toString()}`,
            credentials: 'include'
          }
        })
        if (response?.status === 200) {
          setCodeSent(true)
          setEmailAddress(email)
          Cookies.set(CookieKeys.USER_TOKEN, response?.data?.data?.token, { expires: rememberDevice ? 90 : 1 })
          const currentDate = new Date()
          rememberDevice ? currentDate.setDate(currentDate.getDate() + 90) : currentDate.setDate(currentDate.getDate() + 1)
          Cookies.set(
            CookieKeys.USER_TOKEN_EXPIRED,
            currentDate.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
          )
          Cookies.set(CookieKeys.USER_TOKEN_EMAIL, email)
          pendo.initialize({
            visitor: {
              id: `${email}-${process.env.REACT_APP_NODE_ENV}`,
              email: email
            },
            account: {
              id: themeContext?.branding.displayName
            }
          })
          navigate(`/app/task?${searchParams.toString()}`)
        }
      } catch (error) {
        enqueueSnackbar(`Invalid Code`, { variant: 'error' })
        console.error(error)
      }
    }
    return
  }

  const handleGoBack = () => {
    setCodeSent(false)
  }

  const handleOpenLinks = (url: string) => () => {
    window.open(`${window.location.origin}${url}`, '_blank')
  }

  useEffect(() => {
    // if cookie exists just redirect to task page
    if (Cookies.get(CookieKeys.USER_TOKEN) !== undefined) {
     navigate(`/app/task?${searchParams.toString()}`)
    }
  }, [])

  return (
    <>
      <Container>
        <CardContent>
          <MarginedTextHeader variant='h5'>{description}</MarginedTextHeader>
          <MarginedText variant='body1'>
            To {action} please verify your email address. Enter your email and we will send a verification code to your inbox.
          </MarginedText>
          {codeSent && (
            <ResendContainer>
              <MarginedText variant='body1'>Didn’t receive an email with verification code?</MarginedText>
              <ResendLink id='resend_button' data-cy='resend_button' onClick={hanldeSendCode}>
                Resend
              </ResendLink>
            </ResendContainer>
          )}
          <InputGrid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <InputLabel htmlFor='email-input'>Your Email Address</InputLabel>
              <OutlinedInput
                id='email_input'
                data-cy='email_input'
                placeholder='Your email address'
                margin='dense'
                size='small'
                fullWidth
                value={email}
                error={!!emailError}
                onChange={handleEmailInput}
                disabled={codeSent}
              />
              {emailError && <FormHelperText error>{emailError}</FormHelperText>}
            </Grid>
            <Grid item sm={6} xs={12}>
              {codeSent && (
                <>
                  <InputLabel htmlFor='code-input'>Verification Code</InputLabel>
                  <OutlinedInput
                    id='code_input'
                    data-cy='code_input'
                    placeholder='Verification code'
                    margin='dense'
                    size='small'
                    fullWidth
                    value={code}
                    onChange={handleCodeInput}
                  />
                </>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>
              {codeSent && (
                <FormControlLabel
                  control={<Checkbox id='remember_device' data-cy='remember_device' checked={rememberDevice} onChange={toggleRememberDevice} />}
                  label={<StyleEmailLabel>Remember my email on this device for 90 days</StyleEmailLabel>}
                />
              )}
            </Grid>
          </InputGrid>
        </CardContent>
        <FormActions>
          {codeSent ? (
            <>
              <BackButton variant='outlined' onClick={handleGoBack} color='secondary'>
                Back
              </BackButton>
              <VerifyButton
                id='verify_code_button'
                data-cy='verify_code_button'
                disabled={code === ''}
                variant='contained'
                onClick={hanldeVeify}
                color='primary'
              >
                Verify Email
              </VerifyButton>
            </>
          ) : (
            <Button
              id='send_code_button'
              data-cy='send_code_button'
              disabled={email === ''}
              variant='contained'
              onClick={hanldeSendCode}
              color='primary'
            >
              Send Verification Code
            </Button>
          )}
        </FormActions>
      </Container>
        <TermsContainer>
          {codeSent ? (
            <TermsLink align='center'>
              <Link onClick={handleOpenLinks(`/static/legal/index.html`)} target='_blank'>
                Terms of Service
              </Link>{' '}
              | <Link onClick={handleOpenLinks(`/static/legal/index.html#privacy`)}>Privacy Policy</Link>
            </TermsLink>
          ) : (
            <TermsLink>
              By selecting Send Verification Code, you agree to the{' '}
              <Link onClick={handleOpenLinks(`/static/legal/index.html`)}>Terms of Service</Link> and{' '}
              <Link onClick={handleOpenLinks(`/static/legal/index.html#privacy`)} target='_blank'>
                Privacy Policy
              </Link>{' '}
            </TermsLink>
          )}
        </TermsContainer>
    </>
  )
}

export default VerificationPage
