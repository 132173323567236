/**
 * @file extend this file to add theming to project
 */
import tinycolor from 'tinycolor2'
import { THEME_COLORS } from '@theme/themeColors'

const BRAND_COLOR = '#1c82ac' // BK primary color
const PRIMARY_COLOR = '#228477'
const WHITE_COLOR = '#FFF'
const BLACK_COLOR = '#000'
const LIGHT_GRAY_COLOR = '#DCDFEA'
const MID_GRAY_COLOR = '#979797'
const DARK_GRAY_COLOR = '#595959'

const defaultTheme = {
  logoUrl: '', // logo,
  favIconUrl: '',
  brandName: '',
  partnerId: '',
  palette: {
    primaryColor: '#063c50',
    secondaryColor: '#4caf50',
    tertiaryColor: '#404040'
  }
}

/** overrides default styling of MUI except branding related */
const defaultOverrides = {
  MuiButton: {
    root: {
      fontSize: '1rem',
      padding: '0.15625rem 1rem',
      textTransform: 'capitalize',
      marginLeft: '16px'
    },
    primary: {
      backgroundColor: PRIMARY_COLOR,
      color: WHITE_COLOR
    },
    containedPrimary: {
      backgroundColor: PRIMARY_COLOR,
      '&:hover': {
        backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.85).toString()
      },
      '&:active': {
        backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.7).toString()
      },
      '&:disabled': {
        backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.3).toString(),
        color: WHITE_COLOR
      }
    },
    outlinedPrimary: {
      backgroundColor: WHITE_COLOR,
      border: `1px solid ${PRIMARY_COLOR}`,
      color: BLACK_COLOR,
      padding: '0.15625rem 1rem',
      '&:hover': {
        backgroundColor: LIGHT_GRAY_COLOR,
        borderColor: PRIMARY_COLOR
      },
      '&:active': {
        backgroundColor: LIGHT_GRAY_COLOR,
        borderColor: PRIMARY_COLOR
      },
      '&:disabled': {
        backgroundColor: WHITE_COLOR,
        color: MID_GRAY_COLOR
      }
    },
    outlinedSecondary: {
      backgroundColor: WHITE_COLOR,
      border: `1px solid ${LIGHT_GRAY_COLOR}`,
      color: BLACK_COLOR,
      padding: '0.15625rem 1rem',
      '&:hover': {
        backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.5).toString(),
        borderColor: LIGHT_GRAY_COLOR
      },
      '&:active': {
        backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.8).toString(),
        borderColor: LIGHT_GRAY_COLOR
      },
      '&:disabled': {
        backgroundColor: WHITE_COLOR,
        color: MID_GRAY_COLOR,
        borderColor: LIGHT_GRAY_COLOR
      },
      '& .destructive': {
        backgroundColor: WHITE_COLOR,
        border: `1px solid ${LIGHT_GRAY_COLOR}`,
        color: 'red',
        padding: '0.15625rem 1rem',
        '&:hover': {
          backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.5).toString(),
          borderColor: LIGHT_GRAY_COLOR
        },
        '&:active': {
          backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.8).toString(),
          borderColor: LIGHT_GRAY_COLOR
        },
        '&:disabled': {
          backgroundColor: WHITE_COLOR,
          color: MID_GRAY_COLOR,
          borderColor: LIGHT_GRAY_COLOR
        }
      }
    },
    text: {
      color: BRAND_COLOR,
      backgroundColor: 'transparent'
    },
    textPrimary: {
      color: BRAND_COLOR
    },
    textSecondary: {
      color: DARK_GRAY_COLOR
    }
  },
  MuiTooltip: {
    arrow: {
      color: '#000'
    },
    tooltip: {
      fontSize: '14px',
      backgroundColor: '#000'
    }
  },
  MuiToolbar: {
    root: {
      '@media (min-width: 600px)': {
        height: '64px'
      }
    }
  },
  MUIRichTextEditor: {
    root: {
      marginTop: 10,
      marginBottom: 20,
      border: '1px solid #e0e4e7',
      margin: '10px 0 20px 0',
      borderRadius: 5
    },
    editor: {
      minHeight: 100,
      padding: '0 15px'
    },
    placeHolder: {
      marginLeft: 15
    }
  },
  MuiDialogContent: {
    root: {
      padding: '8px 24px 0'
    }
  },
  MuiDialogActions: {
    root: {
      padding: '12px 24px 24px'
    }
  },
  MuiTabs: {
    root: {
      padding: 0,
      borderBottom: `1px solid ${THEME_COLORS.grey}`,
      maxHeight: '50px',
      flexGrow: 1
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#228477',
      height: '6px'
    }
  },
  MuiTab: {
    root: {
      textTransform: 'capitalize',
      fontSize: '18px',
      marginRight: '56px',
      minWidth: '0',
      '&:focus': {
        opacity: 1
      }
    }
  },
  MuiAccordionSummary: {
    expandIcon: {
      color: BRAND_COLOR
    }
  },
  MuiTextField: {
    root: {
      backgroundColor: WHITE_COLOR,
      '& .MuiInputBase-input': {
        padding: '9.5px 14px'
      },
      '& label.Mui-focused': {
        color: BRAND_COLOR
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: BRAND_COLOR
        }
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: BRAND_COLOR
        }
      }
    }
  },
  MuiTable: {
    root: {
      '& > caption': {
        textAlign: 'center'
      }
    }
  },
  MuiTableRow: {
    root: {
      cursor: 'pointer',
      '& th': {
        fontWeight: 'bold'
      }
    }
  },
  MuiTableCell: {
    root: {
      height: '3.75rem',
      padding: '0 1rem',
      '&:first-of-type': {
        fontWeight: 'bold'
      },
      '&.numeral': {
        textAlign: 'right',
        '& > a': {
          justifyContent: 'flex-end'
        }
      }
    },
    alignRight: {
      flexDirection: 'row'
    },
    body: {
      color: '#000'
    },
    head: {
      color: '#565A5C',
      fontWeight: 'bold',
      padding: '.946875rem 1rem'
    }
  },
  MuiTableSortLabel: {
    root: {
      color: '#565A5C !important',
      '& > svg': {
        fill: `${THEME_COLORS.blue}`,
        marginRight: '-23px',
        position: 'relative',
        top: '-1px'
      }
    }
  },
  MuiLink: {
    root: {
      color: `${THEME_COLORS.blue}`,
      fontWeight: '700',
      textDecoration: 'none'
    }
  },
  MuiBreadcrumbs: {
    separator: {
      '& > svg': {
        fill: BLACK_COLOR
      }
    },
    li: {
      '& a': {
        fontWeight: '700',
        cursor: 'pointer',
        color: BRAND_COLOR,
        '&:hover': {
          textDecoration: 'none'
        }
      },
      '& > span': {
        color: BLACK_COLOR,
        fontWeight: '700'
      }
    }
  },
  MuiSelect: {
    select: {
      padding: '9px',
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  }
}

const defaultComponent = {
  MuiButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        fontSize: '1rem',
        padding: '0.15625rem 1rem',
        'text-transform': 'capitalize',
        marginLeft: '16px'
        // Some CSS
      },
      primary: {
        backgroundColor: PRIMARY_COLOR,
        color: WHITE_COLOR
      },
      containedPrimary: {
        backgroundColor: PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.85).toString()
        },
        '&:active': {
          backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.7).toString()
        },
        '&:disabled': {
          backgroundColor: tinycolor(PRIMARY_COLOR).setAlpha(0.3).toString(),
          color: WHITE_COLOR
        }
      },
      outlinedPrimary: {
        backgroundColor: WHITE_COLOR,
        border: `1px solid ${PRIMARY_COLOR}`,
        color: BLACK_COLOR,
        padding: '0.15625rem 1rem',
        '&:hover': {
          backgroundColor: LIGHT_GRAY_COLOR,
          borderColor: PRIMARY_COLOR
        },
        '&:active': {
          backgroundColor: LIGHT_GRAY_COLOR,
          borderColor: PRIMARY_COLOR
        },
        '&:disabled': {
          backgroundColor: WHITE_COLOR,
          color: MID_GRAY_COLOR
        }
      },
      outlinedSecondary: {
        backgroundColor: WHITE_COLOR,
        border: `1px solid ${LIGHT_GRAY_COLOR}`,
        color: BLACK_COLOR,
        padding: '0.15625rem 1rem',
        '&:hover': {
          backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.5).toString(),
          borderColor: LIGHT_GRAY_COLOR
        },
        '&:active': {
          backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.8).toString(),
          borderColor: LIGHT_GRAY_COLOR
        },
        '&:disabled': {
          backgroundColor: WHITE_COLOR,
          color: MID_GRAY_COLOR,
          borderColor: LIGHT_GRAY_COLOR
        },
        '& .destructive': {
          backgroundColor: WHITE_COLOR,
          border: `1px solid ${LIGHT_GRAY_COLOR}`,
          color: 'red',
          padding: '0.15625rem 1rem',
          '&:hover': {
            backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.5).toString(),
            borderColor: LIGHT_GRAY_COLOR
          },
          '&:active': {
            backgroundColor: tinycolor(LIGHT_GRAY_COLOR).setAlpha(0.8).toString(),
            borderColor: LIGHT_GRAY_COLOR
          },
          '&:disabled': {
            backgroundColor: WHITE_COLOR,
            color: MID_GRAY_COLOR,
            borderColor: LIGHT_GRAY_COLOR
          }
        }
      },
      text: {
        color: BRAND_COLOR,
        backgroundColor: 'transparent'
      },
      textPrimary: {
        color: BRAND_COLOR
      },
      textSecondary: {
        color: DARK_GRAY_COLOR
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      // Name of the slot
      root: {
        backgroundColor: WHITE_COLOR,
        '& .MuiInputBase-input': {
          padding: '9.5px 14px'
        },
        '& label.Mui-focused': {
          color: BRAND_COLOR
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: BRAND_COLOR
          }
        }
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '0px'
      }
    }
  }
}

const defaultProps = {
  MuiButton: {
    disableElevation: true
  }
}

export { defaultTheme, defaultOverrides, defaultProps, defaultComponent }
