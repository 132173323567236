export const SET_PARTNER_ID = 'SET_PARTNER_ID'
export type SET_PARTNER_ID = typeof SET_PARTNER_ID

export const FETCHING_PARTNER_ID = 'FETCHING_PARTNER_ID'
export type FETCHING_PARTNER_ID = typeof FETCHING_PARTNER_ID

export const ERROR_FETCHING_PARTNER_ID = 'ERROR_FETCHING_PARTNER_ID'
export type ERROR_FETCHING_PARTNER_ID = typeof ERROR_FETCHING_PARTNER_ID

export const FETCHING_CURRENT_BRAND = 'FETCHING_CURRENT_BRAND'
export type FETCHING_CURRENT_BRAND = typeof FETCHING_CURRENT_BRAND
export const FETCHED_CURRENT_BRAND = 'FETCHED_CURRENT_BRAND'
export type FETCHED_CURRENT_BRAND = typeof FETCHED_CURRENT_BRAND
export const ERROR_FETCHING_CURENT_BRAND = 'ERROR_FETCHING_CURENT_BRAND'
export type ERROR_FETCHING_CURENT_BRAND = typeof ERROR_FETCHING_CURENT_BRAND
