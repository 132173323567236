/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import '@src/App.css'
import { AppContainer } from '@src/App.styles'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { AppThemeProvider } from '@context/themeContext'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { Layout } from '@components'
import { ComponentType } from 'react'

const ldConfig = {
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_KEY!
}

const App = () => {
  pendo.initialize({
    visitor: {
      id: 'VISITOR-UNIQUE-ID'
    }
  })

  return (
    <AppThemeProvider>
      <AppContainer className='App'>
        <Layout />
      </AppContainer>
    </AppThemeProvider>
  )
}

export default withLDProvider(ldConfig)(App as ComponentType)
