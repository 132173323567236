import { Typography } from '@mui/material'
import { BrandingTitle, BrandingPlaceholder, BrandingContainer } from '@components/styles'
import { useAppTheme } from '@context/themeContext'

export const Header = () => {
  const themeContext = useAppTheme()

  return (
    <BrandingContainer>
      <BrandingTitle>
      <BrandingPlaceholder>
        {themeContext.branding.logoUrl
          ? <img src={themeContext.branding.logoUrl} alt={themeContext.branding.displayName || ''} />
          : <Typography variant='h5'>{themeContext.branding.displayName || ''}</Typography>}
      </BrandingPlaceholder>
    </BrandingTitle>
  </BrandingContainer>
  )
}
