/* eslint-disable @typescript-eslint/no-empty-function */

import { IQueryState } from '@context/taskContext'

export const initialTaskState: IQueryState = {
  token: '',
  action: '',
  slid: '',
  description: null
}
export const initialEmailState = {
  emailAddress: '',
  setEmailAddress: () => {}
}
