/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState, useEffect } from 'react'
import { CardContent, Alert, Link } from '@mui/material'
import { useTask } from '@context/taskContext'
import {
  ConnectionText,
  BoldText,
  ConnectionHeaderCardContent,
  TaskCard,
  TaskTitleText,
  EmailLink,
  CalendarIcon,
  ExpirationText,
  CalendarContainer,
  InstructionText,
  WidgetCardContent
} from '@components/task/styles'
import { TermsLink } from '@components/verification/styles'
import { useNavigate, useSearchParams } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SmartConnect } from '@techbot/partner-portal/partials'
import { useSnackbar } from 'notistack'
import { axiosInstance } from '@utils'
import Cookies from 'js-cookie'
import { CookieKeys } from '@constants'

export const TaskPage = () => {
  const [entityId, setEntityId] = useState<string>('')
  const [partnerId, setPartnerId] = useState<string>('')
  const [institutionId, setInstitutionId] = useState<string>('')
  const [taskId, setTaskId] = useState<string>('')
  const [taskCompleted, setTaskCompleted] = useState<boolean>(false)
  const {
    emailAddress,
    setEmailAddress,
    query: { description, slid }
  } = useTask()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const token = Cookies.get(CookieKeys.USER_TOKEN)
  const expirationDate = Cookies.get(CookieKeys.USER_TOKEN_EXPIRED) ? Cookies.get(CookieKeys.USER_TOKEN_EXPIRED) : ''

  const returnToEmailFlow = () => {
    // return to verify steps and remove user token from cookie
    setEmailAddress('')
    Cookies.remove(CookieKeys.USER_TOKEN)
    Cookies.remove(CookieKeys.USER_TOKEN_EXPIRED)
    Cookies.remove(CookieKeys.USER_TOKEN_EMAIL)
    return navigate(`/app/verification?${searchParams.toString()}`)
  }

  const fetchTaskDetail = async () => {
    // Get slid from link query
    // Get user token from local storage and call wfe get task
    const WFE_URL = process.env.REACT_APP_WFE_SERVICE
    if (WFE_URL !== undefined && token !== '' && slid) {
      const url = `${WFE_URL}/v1/smart-link/tasks/${slid}`
      try {
        const response = await axiosInstance.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.toString() || ''}`,
            credentials: 'include'
          }
        })
        if (response?.status === 200) {
          const properties = response?.data?.data?.componentProperties
          if (response?.data?.data?.status === 'completed') {
            setTaskCompleted(true)
          }
          setEntityId(properties?.entityId)
          setPartnerId(properties?.partnerId)
          setInstitutionId(properties?.institutionId)
          setTaskId(response?.data?.data?.id)
        }
      } catch (error) {
        enqueueSnackbar('Error fetching task', { variant: 'error' })
        console.error(error)
      }
    }
  }

  const handleCompleteTask = async (taskId: string) => {
    // call task complete endpoint
    const WFE_URL = process.env.REACT_APP_WFE_SERVICE
    const URL = `${WFE_URL}/v1/smart-link/tasks/${taskId}/complete`
    try {
      const response = await axiosInstance.post(
        URL,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token?.toString()}`
          }
        }
      )
      if (response?.status === 200) {
        if (response?.data?.data?.status === 'completed') {
          setTaskCompleted(true)
          enqueueSnackbar('Connection Request Task Complete.', { variant: 'success' })
        } else {
          enqueueSnackbar('Connection was successful.', { variant: 'success' })
        }
      }
    } catch (error) {
      enqueueSnackbar('Unable to complete task', { variant: 'error' })
      console.error(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(async () => {
    // if user jumps to task page without a token somehow we redirect them to verification flow
    if (Cookies.get(CookieKeys.USER_TOKEN) === '') {
      return navigate(`/app/verification?${searchParams.toString()}`)
    }
    // if previous session has an email cookie we can use it to populate the email address
    if (Cookies.get(CookieKeys.USER_TOKEN_EMAIL) !== '') {
      setEmailAddress(Cookies.get(CookieKeys.USER_TOKEN_EMAIL) || '')
    }
    await fetchTaskDetail()
  }, [slid])

  const handleOpenLinks = (url: string) => () => {
    window.open(`${window.location.origin}${url}`, '_blank')
  }

  return (
    <div>
      <TaskCard>
        <ConnectionHeaderCardContent>
          <ConnectionText>Connected as</ConnectionText>
          <BoldText> {emailAddress}</BoldText>
          <EmailLink id='change_email_button' data-cy='change_email_button' color='inherit' onClick={returnToEmailFlow}>
            change email
          </EmailLink>
        </ConnectionHeaderCardContent>
      </TaskCard>

      <TaskCard>
        <CardContent>
          <TaskTitleText>{description}</TaskTitleText>
          <CalendarContainer>
            <CalendarIcon className='fa-duotone fa-calendar-clock'></CalendarIcon>
            <ExpirationText>{`For security reasons the connection request session will expire on ${expirationDate}.`}</ExpirationText>
          </CalendarContainer>
          <BoldText>Instructions:</BoldText>
          <InstructionText>Use the widget below to connect your account:</InstructionText>
        </CardContent>
        <WidgetCardContent>
          <SmartConnect
            open={!taskCompleted}
            partnerId={partnerId}
            entityId={entityId}
            handleClose={() => {}}
            smartConnect={true}
            handleConnectionsFetch={() => {}}
            handleCompleteTask={handleCompleteTask}
            taskId={taskId}
            requestedInstitutionId={institutionId}
            setSelectedAccountHit={(item: boolean) => item}
            setIframeLoaded={(item: boolean) => item}
            SSAToken={`Bearer ${token?.toString()}`}
          />
        </WidgetCardContent>
      </TaskCard>
      {taskCompleted && (
        <TaskCard>
          <Alert severity='info' variant='outlined'>
            This task has been completed. No further action is required.
          </Alert>
        </TaskCard>
      )}
        <TermsLink align='center'>
          <Link onClick={handleOpenLinks('/static/legal/index.html')} target='_blank'>Terms of Service</Link> |{' '}
          <Link onClick={handleOpenLinks('/static/legal/index.html#privacy')} target='_blank'>Privacy Policy</Link>
        </TermsLink>
    </div>
  )
}

export default TaskPage
