import styled from 'styled-components'
import { Box } from '@mui/material'

export const AppContainer = styled(Box)`
  padding: 16px;

  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`
