import { IActions, IThemeState } from '@context/themeContext/types'
import {
  FETCHING_CURRENT_BRAND,
  FETCHED_CURRENT_BRAND,
  ERROR_FETCHING_CURENT_BRAND,
  ERROR_FETCHING_PARTNER_ID,
  SET_PARTNER_ID,
  FETCHING_PARTNER_ID,
  initialThemState
} from '@context/themeContext'
import { Reducer } from 'react'

export const reducer: Reducer<IThemeState, IActions> = (state: IThemeState, action: IActions) => {
  switch (action.type) {
    case FETCHING_CURRENT_BRAND: {
      return {
        ...state,
        branding: initialThemState.branding,
        fetchingBranding: true,
        errorFetchingBranding: ''
      } as IThemeState
    }
    case FETCHED_CURRENT_BRAND: {
      return {
        ...state,
        branding: action.payload,
        fetchingBranding: false,
        errorFetchingBranding: ''
      } as IThemeState
    }
    case ERROR_FETCHING_CURENT_BRAND: {
      return {
        ...state,
        branding: initialThemState.branding,
        fetchingBranding: false,
        errorFetchingBranding: action.payload as string
      } as IThemeState
    }
    case FETCHING_PARTNER_ID: {
      return {
        ...state,
        fetchingPatnerId: true,
        errorFetchingPartnerId: '',
        partnerId: null
      } as IThemeState
    }
    case ERROR_FETCHING_PARTNER_ID: {
      return {
        ...state,
        fetchingPatnerId: false,
        errorFetchingPartnerId: action.payload as string,
        partnerId: null
      }
    }
    case SET_PARTNER_ID: {
      return {
        ...state,
        fetchingPatnerId: false,
        errorFetchingPartnerId: '',
        partnerId: action.payload as string
      } as IThemeState
    }
    default:
      return state
  }
}
