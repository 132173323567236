import styled from 'styled-components'
import { Typography, Card, CardActions, Button, Grid } from '@mui/material'
import { THEME_COLORS } from '@theme'

export const MarginedText = styled(Typography)`
  margin: 8px 0;
  font-size: 16px;
  @media only screen and (max-width: 900px) {
    margin: 0px;
  }
`

export const MarginedTextHeader = styled(Typography)`
  margin: 8px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  @media only screen and (max-width: 900px) {
    margin: 8px 0px 30px 0px;
  }
`

export const FormActions = styled(CardActions)`
  flex-direction: row;
  margin: 8px;

  & > button {
    margin-left: 0;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    & > button {
      width: 100%;
      margin-left: 0px !important;
      margin-bottom: 20px;
    }
  }
`

export const Container = styled(Card)`
  width: 700px;
  margin: auto;
  min-height: 300px;
  @media only screen and (max-width: 900px) {
    width: 100%;
    box-shadow: 0px 1px 0px 0 #0003;
  }
`

export const BackButton = styled(Button)`
  background-color: ${THEME_COLORS.white};
  min-width: 140px !important;
  margin-right: 10px;
  @media only screen and (max-width: 900px) {
    margin: 0px;
  }
`

export const VerifyButton = styled(Button)`
  min-width: 140px !important;
`

export const ResendLink = styled(Button)`
  color: ${THEME_COLORS.blue};
  margin: 0px;
  @media only screen and (max-width: 600px) {
    display: contents;
  }
`

export const ResendContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 600px) {
    margin-bottom: 30px;
    display: contents;
  }
`

export const InputGrid = styled(Grid)`
  margin-top: 10px;
`

export const StyleEmailLabel = styled(Typography)`
  white-space: nowrap;
  @media only screen and (max-width: 600px) {
    white-space: break-spaces;
  }
`

export const TermsContainer = styled.div`
  width: 700px;
  margin: 16px auto;
  min-height: 300px;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`

export const TermsLink = styled(Typography)`
  color: #707070;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;


  & > a {
    color: #1182ac;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    cursor: pointer;
  }
`
