/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosResponse } from 'axios'
import { logger } from '@utils'
import { $TSFixMe } from '@src/types'

const source = axios.CancelToken.source()
const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(REQUEST => {
  REQUEST.cancelToken = source.token
  return REQUEST
})

axiosInstance.interceptors.response.use(function (response: AxiosResponse) {
  return response
}, function (error: $TSFixMe) {
  logger.error(error.message as string)
  return Promise.reject(error)
})

export { axiosInstance, source }
