/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-console */

import { Dispatch } from 'react'
import { IActions, FETCHING_CURRENT_BRAND, FETCHED_CURRENT_BRAND, ERROR_FETCHING_CURENT_BRAND, SET_PARTNER_ID } from '@context/themeContext'
import { BRANDING_URL } from '@urls'
import { axiosInstance } from '@utils'
import { $TSFixMe } from '@src/types'

export const getBrandingDetails = async ({ dispatch }: { dispatch: Dispatch<IActions> }) => {
  try {
    dispatch({ type: FETCHING_CURRENT_BRAND })
    const response = await axiosInstance.get(BRANDING_URL())
    dispatch({ type: FETCHED_CURRENT_BRAND, payload: response.data.data })
    dispatch({ type: SET_PARTNER_ID, payload: response.data.partnerId })
  } catch (error: $TSFixMe) {
    dispatch({ type: ERROR_FETCHING_CURENT_BRAND, payload: error.message })
  }
}
